
import { computed, ComputedRef, defineComponent, onMounted, ref, watch } from 'vue';
import { useDailyCampaignStatistics, usePlans } from '@/composables/api';
import { formatLocalTime } from '@/utils/format-time';
import { Channel } from '@/interfaces';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import { getThousandSeparator } from '@/utils/render';
import download from 'downloadjs';
import dayjs from 'dayjs';
import { DEFAULT_PAGE_SIZE, exportDailyCampaignStatistics, getChannels, MAX_PER_PAGE } from '@/apis';

export default defineComponent({
  components: {
    Filter
  },
  setup() {
    const page = ref(1);
    const pageSize = ref(DEFAULT_PAGE_SIZE);
    const startDate = ref();
    const endDate = ref();
    const channelIds = ref();
    const dateRange = ref(['']);
    const channels = ref<Channel[]>([]);
    const channelOptions = ref([]);
    const defaultTime = ref([new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]);

    const enabled = computed(() => channelIds?.value?.length > 0);

    // enabled當開關，enabled為true，已經拿到channelIds時，才執行useDailyCampaignStatistics
    const { data, isLoading, isFetching } = useDailyCampaignStatistics({ page, pageSize, startDate, endDate, channelIds }, { enabled });
    const { data: plans } = usePlans({ page: 1, pageSize: MAX_PER_PAGE });

    const handleDateRangeChange = () => {
      if (!dateRange.value) {
        startDate.value = '';
        endDate.value = '';
        return;
      }

      startDate.value = dateRange.value[0];
      endDate.value = dateRange.value[1];
    };

    const handleFilterChange = (event: FilterEvent) => {
      channelIds.value = event[0];
    };

    const fetchChannels = async() => {
      channels.value = (await getChannels({ query: { pageSize: MAX_PER_PAGE } })).data;
      channelIds.value = channels.value.map(item => item.id); // 組出類似[53,56]之類的參數
    };

    const handleExcelExport = async() => {
      const data = await exportDailyCampaignStatistics({
        query: {
          startDate: startDate.value,
          endDate: endDate.value,
          channelIds: channelIds.value
        }
      });

      const currentDate = dayjs().format('YYYYMMDD');
      // 下載匯出檔案
      download(data, `渠道流量列表_${currentDate}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    };

    onMounted(() => {
      fetchChannels();
    });

    watch(channels, () => {
      channelOptions.value = channels.value.map(({ utmSource, id }) => ({ label: `${utmSource}`, value: `${id}` }));
    });

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.MULTI_SELECTOR,
          label: '渠道名稱',
          placeholder: '請選擇',
          options: channelOptions.value
        }
      ];
    });

    return {
      page,
      data,
      plans,
      dateRange,
      isLoading,
      isFetching,
      filterOptions,
      channelOptions,
      defaultTime,
      getThousandSeparator,
      handleFilterChange,
      formatLocalTime,
      handleDateRangeChange,
      handleExcelExport
    };
  }
});
